import { defineStore } from 'pinia';
import cache from '@/utils/cache';
import type { RouteRecordRaw } from 'vue-router';
import { getUserInfo, login, logout, getMenu } from '@/api/user';
import router, { filterAsyncRoutes } from '@/router';
import { TOKEN_KEY } from '@/enums/cacheEnums';
import { PageEnum } from '@/enums/pageEnum';
import { clearAuthInfo, getToken } from '@/utils/auth';
export interface UserState {
    token: string;
    userInfo: Record<string, any>;
    routes: RouteRecordRaw[];
    menu: any[];
    perms: string[];
}

const useUserStore = defineStore({
    id: 'user',
    state: (): UserState => ({
        token: getToken() || '',
        // 用户信息
        userInfo: {},
        // 路由
        routes: [],
        menu: [],
        // 权限
        perms: []
    }),
    getters: {},
    actions: {
        resetState() {
            this.token = '';
            this.userInfo = {};
            this.perms = [];
        },
        login(playload: any) {
            const { account, password } = playload;
            return new Promise((resolve, reject) => {
                login({
                    username: account,
                    password: password
                })
                    .then((data) => {
                        this.token = data.token;
                        cache.set(TOKEN_KEY, data.token);
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        logout() {
            return new Promise((resolve, reject) => {
                logout()
                    .then(async (data) => {
                        this.token = '';
                        await router.push(PageEnum.LOGIN);
                        clearAuthInfo();
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getUserInfo() {
            return new Promise((resolve, reject) => {
                getUserInfo()
                    .then((data) => {
                        this.userInfo = data.user;
                        this.perms = data.permissions;
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getMenu() {
            return new Promise((resolve, reject) => {
                getMenu()
                    .then((data) => {
                        data.forEach(p => {
                            if (p.paths) {
                                if ((import.meta as any).env.MODE === 'development') {
                                    p.paths = p.paths.replace('www.bjie.top/DigitalScreen', `localhost:3334`);
                                }
                            }
                        });

                        this.menu = data;
                        this.routes = filterAsyncRoutes(data);
                        console.log('getMenu3', this.routes);
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
});

export default useUserStore;
