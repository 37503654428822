import { createPinia } from 'pinia'
import useTabsStore from './modules/multipleTabs';
import { getRouteParams } from './modules/multipleTabs';
import useUserStore from './modules/user';
import useAppStore from './modules/app';
import useSettingStore from './modules/setting';
const store = createPinia()
export default store
export {
    useUserStore,
    useAppStore,
    getRouteParams,
    useTabsStore,
    useSettingStore,
}
